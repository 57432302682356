export const ContactMap = () => (
  <iframe
    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7589.1403280370905!2d30.65044453369033!3d36.89416501305847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c391d21adab4e3%3A0x3565209148a2e448!2sAkdeniz%20University%20Faculty%20of%20Engineering!5e0!3m2!1sen!2str!4v1654333770004!5m2!1sen!2str'
    width='100%'
    height='450'
    className='MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation5'
    style={{ border: 0 }}
    // allowFullScreen=''
    loading='lazy'
    referrerPolicy='no-referrer-when-downgrade'></iframe>
);
