import { useMemo } from 'react';
import { AppBar, Box, Toolbar, Container, Tabs, Tab } from '@mui/material';
import pages from 'src/assets/defaults/pages';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from 'src/assets/images/vetheal-logo-transparent.png';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (e, i) => {
    navigate(pages[i].path);
  };

  const currentTabIdx = useMemo(() => {
    let idx = pages.findIndex(({ path }) => location.pathname.startsWith(path));
    if (idx === -1) idx = 1;
    return idx;
  }, [location]);

  return (
    <AppBar color='transparent' position='static' sx={{ py: 0.3 }}>
      <Container maxWidth='xl'>
        <Toolbar>
          <img src={Logo} height='64px' />
          <Box sx={{ flexGrow: 1 }}>
            <Tabs centered value={currentTabIdx} onChange={handleChange}>
              {pages.map(({ name, path }) => (
                <Tab key={path} label={name} />
              ))}
            </Tabs>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
