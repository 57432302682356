const PAGES = [
  {
    name: 'Home',
    path: '/home'
  },
  {
    name: 'About',
    path: '/about'
  },
  {
    name: 'Diagnose Your Animal',
    path: '/symptom-wizard'
  },
  {
    name: 'Contact',
    path: '/contact'
  }
];

export default PAGES;
